@import "constants";
@import "grid";

* {
     box-sizing: border-box
  }
.page{
  min-height:100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
}
.primary-gradient{
  background-image: $primaryGradient;
}
.primary-background{
  background-color: $black;
}
.black-background{
  background-color: $black;
}
.white-background{
  background-color: $white
}
.white{
  color: $white;
}
.subheading{
  opacity: .5;
}
.gradient-text{
  background: -webkit-linear-gradient($primaryGradientAngle,$primayGradientStartColor, $primayGradientEndColor);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}
.shadow{
  box-shadow: $primaryShadow;
}
.button{
  display:inline-block;
  padding:$mediumSpacingValue;
  padding-left: $largeSpacingValue;
  padding-right: $largeSpacingValue;
  border-radius: $largeSpacingValue;
  color:$white;
  background-image: $primaryGradient;
  font-weight: bold;
}
.background-image-fill{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-padding{
  padding-top: $extraLargeSpacingValue;
  padding-bottom: $extraLargeSpacingValue;
}
.gutter{
  padding-left: $gutterWidth;
  padding-right: $gutterWidth;
}
h2{
  font-size: 80px;
  font-weight: 800;
  color:$white;
}
.max-width-container{
  margin-left:auto;
  margin-right: auto;
  max-width: $maxWidth;
}
.small-max-width-container{
  margin-left:auto;
  margin-right: auto;
  max-width: $smallMaxWidth;
}
a, a:active, a:visited, a:hover{
  color: #005bd3 !important;
}
.Polaris-IndexTable__TableCell{
    color:#666;
}
.Polaris-IndexTable__TableCell .Polaris-Icon{
  height:30px;
  width: 30px;
  margin-left: 0px;
}
.ReactModal__Content {
  position: relative;
  width:90%;
  max-width: 800px;
  height:80%;
  background-color: white;
  margin:auto;
}

  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.75) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
@media only screen and (max-width: $smallBreakpoint) {
  .hide-for-small{
    display: none;
  }
}
