$black:#000;
$white:#fff;
$maxWidth: 1500px;
$smallMaxWidth: 1000px;
$gutterWidth: 20px;
$globalRadius: 8px;
$smallBreakpoint: 800px;
$mediumBreakpoint: 1200px;
$largeBreakpoint: 1800px;
$primaryColor:#326bf1;
$primaryBackgroundColor: #fff;
$buttonBackgroundColor: $primaryColor;
$buttonForegroundColor: $white;
$primaryTextColor:rgba(255,255,255,1);
$secondaryTextColor:rgba(255,255,255,.5);
$primayGradientStartColor:#2353ca;
$primayGradientEndColor:#2666ff;
$primaryGradientAngle:323deg;
$primaryGradient:linear-gradient($primaryGradientAngle, $primayGradientStartColor 0%, $primayGradientEndColor 100%);
$primaryShadow:rgba(0, 0, 0, 0.2) 0px 15px 24px;
$secondaryBackgroundColor: #F2F7FF;
$extraSmallSpacingValue: 5px;
$smallSpacingValue: 10px;
$mediumSpacingValue: 20px;
$largeSpacingValue: 40px;
$extraLargeSpacingValue: 80px;
$extraLargeFontSize:80px;
$largeFontSize:30px;
$mediumFontSize:20px;
$smallFontSize:16px;
$extraSmallFontSize:12px;
